import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import MetaData from '../components/MetaData';
import Layout from '../components/layout/Layout';

const GeneralTermsOfEngagement = () => {
  return (
    <>
      <MetaData
        title='General Terms of Engagement'
        description='Nexton respects your privacy. Any information collected on our website or other owned sites is treated with utmost confidentiality.'
      />
      <Layout selected='privacy-policy'>
        <Flex justifyContent='center' alignItems='center' flexWrap='wrap' flexDirection='column' mx='13px' my='50px'>
          <Box maxWidth='1024px'>
            <Heading color='nextonBlue' fontSize={[4, 5, 6]} fontWeight='500' textAlign='left' mb={3} as='h1'>
              General Terms of Engagement
            </Heading>
            <Text fontSize={[2, 2, 2]} lineHeight='25px' textAlign='justify' mt={3}>
              <div className='body-post'>
                <br />
                <ol start='1'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': 'underline' }}>Method of performing services.</strong>
                    <span>
                      &nbsp;Contractor will determine the method, details, and means of performing the Services.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Status of Contractor. </strong>
                    <span>&nbsp;Contractor enters into this Agreement and will remain throughout the term of the </span>
                    <span style={{ 'text-decoration': ' underline' }}>Agreement</span>
                    <span>
                      , as an independent contractor. Contractor agrees that it is not an employee, partner, agent, or
                      principal of Company.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>No Agency.</strong>
                    <span>&nbsp;</span>
                    <span>
                      Contractor is not authorized to and shall not act as the agent or agency of Company or bind or
                      commit the Company to any obligation to any third party, or incur any liability on behalf of the
                      Company, or accept payments on behalf of the Company, or make any representations on behalf of the
                      Company concerning acceptance of orders, delivery dates, performance guarantees, allowances,
                      returns, settlements, adjustments, or similar matters, unless Contractor shall be expressly
                      authorized in writing by the Company to do so.
                    </span>
                    <span>&nbsp;</span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Supervision.</strong>
                    <span>
                      &nbsp;All of the services to be performed by Contractor, including but not limited to the
                      Services, will be as agreed between Contractor and the Company. Contractor will be required to
                      report to the Company and its designated representative concerning the Services performed under
                      this Agreement. The nature and frequency of these reports will be left to the discretion of the
                      Company and/or its designated representative.
                    </span>
                    <span>&nbsp;</span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Contractor Personnel.</strong>
                    <span>&nbsp;For the purposes of this Agreement, &ldquo;</span>
                    <span style={{ 'text-decoration': ' underline' }}>Contractor Personnel</span>
                    <span>
                      &rdquo; means all employees of Contractor or any Permitted Subcontractors involved in the
                      performance of Services. The Contractor Personnel is not and shall not be employees of the
                      Company. Contractor is solely responsible for all Contractor Personnel and for the payment of
                      their compensation, including, if applicable, withholding of income taxes and the payment and
                      withholding of social security and other payroll taxes, unemployment insurance, workers&rsquo;
                      compensation insurance, and disability benefits. Prior to any Contractor Personnel performing any
                      Services hereunder, Contractor shall': ' (i) ensure that Contractor Personnel have the legal right
                      to work in the applicable jurisdiction; (ii) expressly advise the Contractor Personnel of the
                      terms of th
                    </span>
                    <span>e Services </span>
                    <span>Agreement and </span>
                    <span>these General Terms of Engagement</span>
                    <span>
                      ; (iii) at its sole cost and expense, conduct background checks on such Contractor Personnel.{' '}
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Subcontract</strong>
                    <span style={{ 'text-decoration': ' underline' }}>.</span>
                    <span>
                      &nbsp;Contractor shall not, without the prior written approval of Company, which consent may be
                      given or withheld in Company&rsquo;s sole discretion, engage any Third Party to perform Services
                      hereunder. Company&rsquo;s approval of any such Third Party (each approved Third Party, a &ldquo;
                    </span>
                    <span style={{ 'text-decoration': ' underline' }}>Permitted Subcontractor</span>
                    <span>
                      &rdquo;) shall not relieve Contractor of its representations, warranties, or obligations under the
                      Agreement. For the purposes of this clause, &ldquo;Third Party&rdquo; means any person other than
                      Company or Contractor; the Parties&rsquo; affiliates are Third Parties too. Without limiting the
                      foregoing, Contractor shall': ' (i) be responsible and liable for the acts and omissions of each
                      such Permitted Subcontractor (including such Permitted Subcontractor&rsquo;s employees who, to the
                      extent providing Services or creating work product, shall be deemed Contractor Personnel) to the
                      same extent as if such acts or omissions were by Contractor or its employees; (ii) name Company a
                      third-party beneficiary under Contractor&rsquo;s agreement with each Permitted Subcontractor with
                      respect to the Services or work product; and (iii) be responsible for all fees and expenses
                      payable to, by or on behalf of each Permitted Subcontractor in connection with this Agreement,
                      including, if applicable, withholding of income taxes and the payment and withholding of social
                      security and other payroll taxes, unemployment insurance, workers&rsquo; compensation insurance
                      and disability benefits;{' '}
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>
                      Contractor shall comply with all rules and procedures communicated to Contractor by Company,
                      including those related to safety, security
                    </span>
                    <span>,</span>
                    <span>&nbsp;and confidentiality.</span>
                    <span>&nbsp;</span>
                  </li>
                </ol>
                <p>
                  <strong style={{ 'text-decoration': ' underline' }}>COMPENSATION</strong>
                  <span>&nbsp;</span>
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='8'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>&nbsp;</span>
                    <strong style={{ 'text-decoration': ' underline' }}>Costs and Expenses</strong>
                    <span>. </span>
                    <span>
                      Unless otherwise approved for reimbursement by the Company, Contractor shall be responsible for
                      all of his own expenses, including but not limited to, all rental costs, salaries, taxes,
                      licenses, permits, postage, telephone, telegraph, courier and traveling expenses. Company will
                      reimburse Contractor for all{' '}
                    </span>
                    <span>pre-approved</span>
                    <span>
                      &nbsp;reasonable and documented expenses related to travel and telephone bills actually incurred
                      by Contractor in performing Services under this Agreement. Contractor will provide Company with
                      receipts for all such expenses.
                    </span>
                    <span>&nbsp;</span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>No Benefits.</strong>
                    <span>
                      &nbsp;The Company and Contractor agree that Contractor and its Assistants is not entitled to the
                      rights and/or benefits that may be afforded to Company&rsquo;s employees, including, without
                      limitation, disability or unemployment insurance, workers&#39; compensation, medical insurance,
                      sick leave, paid vacation, 401k participation and/or any other employment benefit. Contractor and
                      its Assistants shall not be entitled to paid vacation time, paid leave, end-of-year bonuses,
                      overtime payments, holiday payments, or any other payment beyond the hourly fees for Services
                      performed. If Contractor and/or any of its Assistants is reclassified by a state or federal agency
                      or court as the Company&rsquo;s employee, Contractor and/or the corresponding Assistant will
                      become a reclassified employee and will receive no benefits from the Company, except those
                      mandated by state or federal law, even if by the terms of the Company&rsquo;s benefit plans or
                      programs of the Company in effect at the time of such reclassification, Contractor and/or the
                      corresponding Assistant would otherwise be eligible for such benefits. To the extent Contractor
                      otherwise would be eligible for any Company employee benefit but for the express terms of this
                      Agreement, Contractor (on behalf of itself and its Assistants) hereby expressly declines to
                      participate in such Company employee benefits.
                    </span>
                    <span>&nbsp;</span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>
                      Contractor is responsible for providing, at its own expense, disability, unemployment, and other
                      insurance, workers&#39; compensation, training, permits, and licenses for its Assistants.
                    </span>
                  </li>
                </ol>
                <p>
                  <strong style={{ 'text-decoration': ' underline' }}>
                    REPRESENTATIONS, WARRANTIES AND COVENANTS OF CONTRACTOR
                  </strong>
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='11'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Time and Place of Performing Work.</strong>
                    <span>
                      &nbsp;Contractor will perform the Services under this Agreement at the locations referenced in
                      Exhibit A (Services Agreement).
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Tools, Materials, and Equipment.</strong>
                    <span>
                      &nbsp;Contractor will supply all tools, materials, and equipment required to perform the services
                      under this Agreement, except that Company may provide access to certain databases and platforms,
                      per requirements of the end customer. Company under no circumstances will be obliged to supply any
                      tools, materials or equipment of any kind required to perform the Contractor&rsquo;s services, nor
                      to reimburse the expenses or fees in which the Contractor may incur. Contractor shall be
                      responsible for obtaining all necessary equipment to perform its task at its own expense.
                      Contractor shall not use open source code or third party source code except as permitted by the
                      Company in writing. Contractor shall not use any confidential information of any third party when
                      performing the Services.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Contractor&rsquo;s Qualifications.</strong>
                    <span>
                      &nbsp;Contractor represents that it has the qualifications and skills necessary to perform the
                      Services under this Agreement in a professional and workmanlike manner and that Contractor will
                      perform the Services to the best of his ability and will devote substantial and sufficient time to
                      perform the Services in a timely and reliable manner. Contractor warrants that the Services will
                      be performed in a professional and workmanlike manner in accordance with the generally accepted
                      industry standards and practices for similar services, using personnel with the requisite skill,
                      experience and qualifications, and that it shall devote adequate resources to meet its obligations
                      under this Agreement. Contractor warrants that he holds the highest ethical standards, has no
                      criminal history and has no history of financial default or indebtedness.
                    </span>
                    <span>&nbsp;</span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Audits.</strong>
                    <span>
                      &nbsp;Contractor shall, at its sole cost and expense, maintain complete and accurate books and
                      records concerning the Services including, without limitation, the documents supporting amounts
                      invoiced by Contractor to Company and the direct costs, expenses and disbursements made or
                      incurred in connection with the Services and shall retain such records for a period of two (2)
                      years following termination of expiration of this Agreement. Company and its duly authorized
                      representatives shall have the right, upon reasonable written notice, for the term of this
                      Agreement and for a period of two (2) years following termination or expiration of this Agreement,
                      to examine and copy (without charge to Company) such books and records, Company shall conduct such
                      audits during Contractor&rsquo;s normal business hours and in such a manner as not to unreasonably
                      interfere with Contractor&rsquo;s normal business operations.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Workers&#39; Compensation.</strong>
                    <span>
                      &nbsp;Contractor agrees to provide workers&#39; compensation insurance for Contractor&rsquo;s own
                      employees and Assistants and agrees to hold harmless and indemnify Company for any and all claims
                      arising out of any injury, disability, or death of any of Contractor&rsquo;s employees or
                      Assistants. Contractor agrees to comply with any and all existent social security and labor laws
                      applicable in the region. Contractor shall further hold harmless and indemnify Company for any
                      employment or labor lawsuit, claim, or demand brought by any worker, contractor, employee,
                      Assistant or any other person or individual providing any Services on behalf of Contractor against
                      Company or any of its affiliates or control persons, and Contractor acknowledges that no
                      employment relationship exists between Company and Contractor or Contractor&rsquo;s Assistants,
                      employees, contractors, or workers.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>
                      Contractor will not make any payments whatsoever to any vendor, or agent or any other party,
                      except as otherwise provided in this Agreement, with regards to any Services it performs for or on
                      behalf of the Company and/or business it obtains, administers and/or receives compensation for
                      from the Company.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>
                      Consulting or other services for competitors.
                    </strong>
                    <span>
                      &nbsp;Contractor represents and warrants that Contractor does not presently perform or intend to
                      perform, during the term of the Agreement, consulting or other services for, or engage in or
                      intend to engage in an employment relationship with, companies whose businesses or proposed
                      businesses in any way involve products or services which would be competitive with the
                      Company&rsquo;s products or services, or those products or services proposed or in development by
                      the Company during the term of the Agreement. If, however, Contractor decides to do so, in advance
                      of accepting such work, Contractor will promptly notify the Company in writing, specifying the
                      organization with which Contractor proposes to consult, become employed by, or to provide services
                      to and to provide information sufficient to allow the Company to determine if such work would
                      conflict with the terms of this Agreement, the interests of the Company or further services which
                      the Company might request of Contractor. If the Company determines that such work conflicts with
                      the terms of this Agreement, the Company reserves the right to terminate this Agreement
                      immediately.
                    </span>
                    <span>&nbsp;</span>
                  </li>
                  <li>
                    <strong style={{ 'text-decoration': ' underline' }}>Legal Compliance.</strong>
                    <span>
                      &nbsp;Contractor is responsible for compliance with all applicable laws, statutes, rules,
                      regulations and ordinances that may apply to the performance of the Services and this Agreement,
                      and hereby represents and warrants that it is in compliance with the same as of the Effective Date
                      and further represents that throughout the duration of this Agreement, Contractor will remain in
                      compliance. Contractor represents, warrants and covenants that it shall comply with all applicable
                      laws and regulations, including, without limitation, United States laws such as United States
                      Foreign Corrupt Practices Act of 1977 and the Export Administration Act, as may be amended from
                      time to time, in performing its duties hereunder and in any of its dealings with respect to the
                      Services. Contractor shall promptly notify Company in the event Contractor knows or has reason to
                      believe that any act or refrainment from acting required by or contemplated by this Agreement
                      violates any applicable law, rule or regulation (whether criminal or non-criminal) or if it
                      becomes aware that any Services contain a defect which could create a hazard or risk of serious
                      injury or death. It is the intent of the Parties that no payments or transfers of value by
                      Contractor in connection with this Agreement shall be made which have the purpose or effect of
                      public or commercial bribery, or acceptance of or acquiescence in, extortion, kickbacks, or other
                      unlawful or improper means of obtaining business. Contractor further represents and warrants that
                      it has obtained all necessary business permits and licenses that may be required to carry out the
                      Services, including any permits that might be required by the state or locality in which
                      Contractor will perform the Services and agrees to maintain such required permits for the duration
                      of the time that Contractor will provide the Services.
                    </span>
                    <br />
                    <br />
                    <span>
                      Furthermore, Contractor represents and warrants that it is, and will perform all necessary actions
                      to remain in, compliance with all applicable Executive Orders, laws, rules, regulations and
                      sanctions administered, enforced or implemented by the United States Treasury Department&rsquo;s
                      Office of Foreign Assets Control (&ldquo;OFAC&rdquo;) and any other Governmental Authority&rsquo;s
                      rules, regulations and sanctions related to foreign asset control (collectively, the
                      &ldquo;Sanctions&rdquo;). Accordingly, Contractor hereby covenants that it will promptly notify
                      Company if it becomes the subject of any such Sanctions during the course of the present Contract.
                      Without limitation of the foregoing, Contractor shall not, directly or indirectly, include or
                      involve, or sell, provide, export, re-export, transfer, divert, loan, lease, consign, or otherwise
                      release or dispose of any goods, services or technology supplied hereunder to, or otherwise
                      involve in any capacity, any prohibited, sanctioned, or designated party and/or country under the
                      United Nations or United States, including but not limited to parties on the List of Specially
                      Designated Nationals and Blocked Persons administered by the OFAC. In the event that Contractor
                      was indeed subject to any such Sanctions before the Effective Date, the present Contract shall be
                      deemed null and void. Notwithstanding anything in the present Contract, any confidentiality and
                      non-disclosure agreements entered into by the parties shall remain in full force and effect.
                    </span>
                  </li>
                </ol>

                <p>
                  <strong style={{ 'text-decoration': ' underline' }}>TERMINATION</strong>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    Upon expiration or termination of this Agreement for any reason, or at any other time upon the
                    Company&#39;s written request, Contractor shall within 5 calendar days after such expiration or
                    termination': '
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    (a) deliver to the Company all deliverables (whether complete or incomplete) and all materials,
                    equipment, and other property provided for Contractor&acute;s use by the Company;
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    (b) deliver to the Company all tangible documents and other media, including any copies, containing,
                    reflecting, incorporating, or based on the confidential information;
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    (c) permanently erase all of the Confidential Information (as defined in the &ldquo;Confidential
                    Information and Invention Assignment Agreement&rdquo; executed with the Company on or about the date
                    hereof) from your computer systems; and
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    (d) certify in writing to the Company that Contractor has complied with the requirements of this
                    clause.
                  </span>
                </p>
                <p>
                  <span />
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='19'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>
                      Cancellation, expiration or earlier termination of the Agreement shall not relieve the Parties of
                      obligations and provisions that by their nature should survive such cancellation, expiration or
                      termination, including without limitation, governing law, dispute resolution provisions,
                      warranties, remedies, indemnification obligations, and confidentiality.
                    </span>
                  </li>
                </ol>
                <p>
                  <strong style={{ 'text-decoration': ' underline' }}>RESTRICTIVE COVENANTS</strong>
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='20'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Non-Solicitation of Customers.</strong>
                    <span>
                      &nbsp;Contractor covenants and agrees that during the term of the Agreement and for twenty four
                      (24) months immediately following the termination of Contractor&rsquo;s relationship with Company
                      for any reason, whether voluntary or involuntary, with or without cause, the Contractor shall not,
                      directly or indirectly, call upon, solicit or attempt to solicit, divert, or take away any
                      business from, or perform any services whatsoever for, any of Company&rsquo;s Customers or
                      prospective customers with whom Contractor had Material Contact during the last two (2) years of
                      Contractor&rsquo;s engagement with Company. For purposes of this section &ldquo;Material
                      Contact&rdquo; shall mean selling, marketing or providing services to Company&rsquo;s Customer or
                      prospective customers.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Non-Solicitation</strong>
                    <span>
                      &nbsp;Contractor covenants and agrees that during the term of this Agreement and for a period of
                      (24) months immediately following the termination of Contractor&rsquo;s relationship with Company
                      for any reason, whether voluntary or involuntary, with or without cause, Contractor shall not
                      either directly or indirectly solicit for employment or hire any of Company&rsquo;s directors,
                      officers, employees or contractors or any of its affiliates, except that Contractor shall not be
                      precluded from hiring any such person who (i) initiates discussions regarding such employment
                      without any direct or indirect solicitation by Contractor, or (ii) responds to any public
                      advertisement placed by Contractor.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Non-Competition.</strong>
                    <span>
                      &nbsp;Contractor covenants and agrees that during the term of this Agreement and for a period of
                      twenty four (24) months immediately following the termination of the Contractor&rsquo;s
                      relationship with Company for any reason, whether voluntary or involuntary, with or without cause,
                      Contractor will not, directly or indirectly': ' (i) own, manage, operate, control or participate
                      in the ownership, management, operation of, or be connected as an officer, partner, director or
                      otherwise, or have any financial interest in any company in the industry of the end customer for
                      which the contractor is providing services, and in the market of the Company, or any other
                      business that is (a) significant to Company and its subsidiaries and affiliates as a whole and (b)
                      during Contractor&rsquo;s relationship hereunder is in competition with the business conducted by
                      Company and any of its subsidiaries or affiliates in any geographic region where such business is
                      being conducted; provided that, ownership, for personal investment purposes only, of no more than
                      five (5) percent of the voting stock of any publicly held corporation shall not constitute a
                      violation of this section.
                    </span>
                  </li>
                </ol>
                <p>
                  <strong style={{ 'text-decoration': ' underline' }}>INDEMNIFICATION</strong>
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='23'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>
                      Contractor agrees to indemnify, defend and hold harmless the Company and its affiliates, and their
                      directors, officers, employees and agents, against any tax, loss, claim, damage (whether ordinary,
                      direct, indirect,{' '}
                    </span>
                    <span style={{ 'text-decoration': ' underline' }}>incidental</span>
                    <span>
                      , special, consequential, or exemplary), liability, judgments, fines, penalties, cost or expense,
                      including reasonable attorney&rsquo;s fees and other legal expenses (collectively
                      &ldquo;Loss&rdquo;), arising directly or indirectly from or in connection with (i) any negligent,
                      reckless or intentionally wrongful act of Contractor or Contractor&rsquo;s Assistants (as defined
                      below), employees, contractors or agents, (ii) any breach by the Contractor or Contractor&rsquo;s
                      Assistants,&nbsp;of any of the representations, warranties and/or covenants contained in this
                      Agreement, (iii) any failure of Contractor to perform the Services in accordance with all
                      applicable laws, rules and regulations, (iv) any acts or omissions, negligence, willful or gross
                      misconduct, or fraud of Contractor or its Assistants, including, but not limited to, third party
                      claims, claims made by any government and claims for property damage or personal injury to the
                      Contractor&rsquo;s Assistants or (v) any violation or claimed violation of a third party&rsquo;s
                      rights resulting in whole or in part from the Company&rsquo;s use of the inventions or other
                      deliverables of Contractor under this Agreement.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>
                      Contractor shall defend and indemnify Company its affiliates and subcontractors from and against
                      all Losses, arising from failure to pay in due time salary, wages, any social security
                      contributions, severance or the breach of any other terms of employment towards its Assistants, in
                      connection with labor, social security, health, safety and environmental regulations.
                    </span>
                    <span>&nbsp;</span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>
                      Contractor shall defend and indemnify Company, its affiliates and subcontractors from and against
                      all Losses, of every kind and character without limitation, arising in favor of or made by any
                      third party on account of bodily injury, disease, death or damage to, destruction of or loss of
                      property of such third party to the extent resulting from or attributable to any negligent act or
                      omission or willful misconduct of Contractor or its Assistants, which are caused by, result from
                      or arise in connection with this Agreement.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>
                      Contractor shall defend and indemnify Company, its affiliates and subcontractors from and against
                      all Losses for liabilities for injury to, or death of employees of Contractor or its Assistants,
                      or damage caused to Contractor&rsquo;s property or personnel, or the property or personnel of its
                      Assistants arising out of or in connection with the supply by Contractor of Services.
                    </span>
                  </li>
                </ol>
                <p>
                  <strong style={{ 'text-decoration': ' underline' }}>GENERAL PROVISIONS.</strong>
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='27'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Notices.</strong>
                    <span>
                      &nbsp;Any notice, demand or request required or permitted to be given under this Agreement shall
                      be in writing and shall be deemed sufficient when delivered personally or by overnight courier or
                      sent by email, or 48 hours after being deposited in the U.S. mail as certified or registered mail
                      with postage prepaid, addressed to the party to be notified at such party&rsquo;s address as set
                      forth on the signature page, as subsequently modified by written notice, or if no address is
                      specified on the signature page, at the most recent address set forth in the Company&rsquo;s books
                      and records.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>
                      Entire Agreement of the Parties Modifications.
                    </strong>
                    <span>
                      &nbsp;Except for the &ldquo;Non-Disclosure and Intellectual Property Transfer Agreement&rdquo;
                      executed on or about the date hereof, this Agreement supersedes any and all agreements, either
                      oral or written, between the Parties with respect to the rendering of Services by Contractor for
                      Company and contains all of the representations, covenants, and agreements between the Parties
                      with respect to the rendering of those Services.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Set-off.</strong>
                    <span>
                      &nbsp;If either Party commits a breach of its obligation under the Agreement, the non-breaching
                      Party shall be entitled to set-off and apply any and all amounts, at any time owed or held by the
                      non-breaching Party or any of its affiliates, to or for the credit or the account of the breaching
                      Party in any currency against any amount, irrespective of whether or not the non-breaching Party
                      or any of its affiliates shall have made any demand under this Agreement and although such
                      obligations may be unmatured.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Arbitration.</strong>
                  </li>
                </ol>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    (a) Any dispute, controversy, or claim arising out of or relating to this agreement (including
                    without limitation the suspension, termination, material breach, and/or validity hereof) (a &ldquo;
                  </span>
                  <span>Dispute</span>
                  <span>&rdquo;) shall be submitted to mandatory final and binding arbitration (&ldquo;</span>
                  <span>Arbitration</span>
                  <span>&rdquo;) before the International Centre for Dispute Resolution (the &ldquo;</span>
                  <span>Arbitral</span>
                  <span>
                    &rdquo;), in accordance with the rules of the Arbitral in effect as of the date of this Agreement
                    (the &ldquo;Rules&rdquo;). The Rules are incorporated herein by this reference. Judgment on the
                    Dispute shall be in writing with written findings of fact and shall be final and non-appealable.
                    This Section hereof set forth the sole recourse for the settlement of any disputes arising out of,
                    in connection with, or related to this Agreement. Notwithstanding the foregoing, either party may
                    seek an injunction or other equitable relief from a court of competent jurisdiction without having
                    to submit to Arbitration.
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    (b) The Arbitration&rsquo;s panel shall be composed of three (3) arbitrators of any nationality
                    (collectively, the &ldquo;Tribunal&rdquo;).
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>(c) The forum for the Arbitration shall be Delaware, US.</span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    (d) The costs of administering the Arbitration (including Arbitral fees) shall be borne equally by
                    the parties. Each party shall also bear its own expenses with respect to its participation in the
                    Arbitration.
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    (e) All hearings, proceedings, and written and oral submissions made with respect to the Arbitration
                    shall be in English.
                  </span>
                </p>
                <p>
                  <span />
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='31'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Governing Law.</strong>
                    <span>
                      &nbsp;This Agreement will be governed by and construed in accordance with the laws of Delaware,
                      United States, without regard to its conflicts of laws principles.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Force Majeure.</strong>
                    <span>
                      &nbsp;Neither Party shall be liable hereunder for any failure or delay in the performance of its
                      obligations under this Agreement, if such failure or delay is on account of causes beyond its
                      control, including labor disputes, civil commotion, war, fires, floods, inclement weather,
                      governmental regulations or controls, casualty, government authority, strikes, terrorism, or acts
                      of God, in which event the non-performing Party shall be excused from its obligations for the
                      period of the delay and for a reasonable time thereafter. Each Party shall use reasonable efforts
                      to notify the other party of the occurrence of such an event within three (3) business days of its
                      occurrence.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Remedies.</strong>
                    <span>
                      &nbsp;Contractor acknowledges that violation of this Agreement by Contractor may cause the Company
                      irreparable harm, and therefore that the Company will be entitled to seek extraordinary relief in
                      court, including, but not limited to, temporary restraining orders, preliminary injunctions and
                      permanent injunctions without the necessity of posting a bond or other security (or, where such a
                      bond or security is required, that a $1,000 bond will be adequate), in addition to and without
                      prejudice to any other rights or remedies that the Company may have for a breach of this
                      Agreement.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Waiver; Rights and Remedies Cumulative.</strong>
                    <span>
                      &nbsp;No waiver of any term or right in this Agreement shall be effective unless in writing,
                      signed by an authorized representative of the waiving party. The failure of either party to
                      enforce any provision of this Agreement shall not be construed as a waiver or modification of such
                      provision, or impairment of its right to enforce such provision or any other provision of this
                      Agreement thereafter. The rights and remedies of the Parties herein provided shall be cumulative
                      and not exclusive of any rights or remedies provided by law or equity.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Counterparts.</strong>
                    <span>
                      &nbsp;This Agreement may be executed by facsimile or electronic signature and in one or more
                      counterparts, each of which will be deemed to be an original, but all of which together will
                      constitute one and the same instrument, without necessity of production of the others. Execution
                      of a facsimile or scanned copy will have the same force and effect as execution of an original,
                      and a facsimile or scanned signature will be deemed an original and valid signature.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Electronic Delivery.</strong>
                    <span>
                      &nbsp;The Company may, in its sole discretion, decide to deliver any documents related to this
                      Agreement or any notices required by applicable law or the Company&rsquo;s Certificate of
                      Incorporation or Bylaws by email or any other electronic means. &nbsp;Contractor hereby consents
                      to (i) conduct business electronically (ii) receive such documents and notices by such electronic
                      delivery and (iii) sign documents electronically and agrees to participate through an on-line or
                      electronic system established and maintained by the Company or a third party designated by the
                      Company.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>LIMITATION OF LIABILITY.</strong>
                    <strong>
                      &nbsp;IN NO EVENT SHALL COMPANY OR ITS SUBSIDIARIES, AFFILIATES, SHAREHOLDERS, DIRECTORS,
                      OFFICERS, EMPLOYEES AND LICENSORS BE LIABLE UNDER THIS AGREEMENT TO CONTRACTOR FOR INDIRECT,
                      CONSEQUENTIAL, SPECIAL, INCIDENTAL, STATUTORY, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT
                      LIMITATION, LOST PROFITS, LOST SAVINGS AND LOST REVENUES, LOSS OF USE, LOSS OF TIME, SHUTDOWN OR
                      SLOWDOWN COSTS, INCONVENIENCE, LOSS OF BUSINESS OPPORTUNITIES, DAMAGE TO GOOD WILL OR REPUTATION,
                      OR OTHER ECONOMIC LOSS, REGARDLESS OF WHETHER SUCH LIABILITY IS BASED ON BREACH OF CONTRACT, TORT,
                      STRICT LIABILITY OR OTHERWISE, AND EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH
                      DAMAGES COULD HAVE BEEN REASONABLY FORESEEN.
                    </strong>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>CAP ON MONETARY LIABILITY.</strong>
                    <strong>
                      &nbsp;IN NO EVENT WILL THE COLLECTIVE AGGREGATE LIABILITY OF COMPANY AND ITS SUBSIDIARIES,
                      AFFILIATES, SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES AND LICENSORS UNDER OR IN CONNECTION WITH
                      THIS AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
                      CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED THE FEES OWED BY
                      COMPANY TO CONTRACTOR. THE FOREGOING LIMITATION APPLIES NOTWITHSTANDING THE FAILURE OF ANY AGREED
                      OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
                    </strong>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Survival.</strong>
                    <span>
                      &nbsp;Any Section that by its context is intended to survive, shall survive any termination or
                      expiration of this Agreement.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Severability.</strong>
                    <span>
                      &nbsp;If any provision of this Agreement is invalid, illegal or unenforceable in any jurisdiction,
                      such invalidity, illegality or unenforceability shall not affect any other term or provision of
                      this Agreement or invalidate or render unenforceable such term or provision in any other
                      jurisdiction. Upon such determination that any term or other provision is invalid, illegal or
                      unenforceable, the parties hereto shall negotiate in good faith to modify this Agreement so as to
                      effect the original intent of the parties as closely as possible in a mutually acceptable manner
                      in order that the transactions contemplated hereby be consummated as originally contemplated to
                      the greatest extent possible. The Company and Contractor have attempted to limit
                      Contractor&rsquo;s right to solicit employees and customers only to the extent necessary to
                      protect the Company from unfair competition. &nbsp;Should a court of competent jurisdiction
                      determine that the scope of the covenants contained in Article VIII exceeds the maximum
                      restrictiveness such court deems reasonable and enforceable, the parties intend that the court
                      should reform, modify and enforce the provision to such narrower scope as it determines to be
                      reasonable and enforceable under the circumstances existing at that time. &nbsp;In the event that
                      any court or government agency of competent jurisdiction determines that, notwithstanding the
                      terms of this Agreement specifying Contractor&rsquo;s relationship with the Company as that of an
                      independent contractor, Contractor&rsquo;s provision of Services to the Company is not as an
                      independent contractor but instead as an employee under the applicable laws, then solely to the
                      extent that such determination is applicable, references in this Agreement to the relationship
                      between Consultant and the Company shall be interpreted to include an employment relationship, and
                      this Agreement shall not be invalid and unenforceable but shall be read to the fullest extent as
                      may be valid and enforceable under the applicable laws to carry out the intent and purpose of this
                      Agreement.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Assignment.</strong>
                    <span>
                      &nbsp;Contractor shall not assign or otherwise transfer any of its rights, or delegate or
                      otherwise transfer any of its obligations or performance, under this Agreement, in each case
                      whether voluntarily, involuntarily, by operation of law or otherwise, without Company&rsquo;s
                      prior written consent. For purposes of the preceding sentence, and without limiting its
                      generality, any merger, consolidation or reorganization involving Contractor (if applicable,
                      regardless of whether Contractor is a surviving or disappearing entity) will be deemed to be a
                      transfer of rights, obligations or performance under this Agreement for which Company&rsquo;s
                      prior written consent is required. No delegation or other transfer will relieve Contractor of any
                      of its obligations or performance under this Agreement. Any purported assignment, delegation or
                      transfer in violation of this Section 11.15 is void. The Company may assign any of its rights and
                      obligations under this Agreement.&nbsp;
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Successors.</strong>

                    <span>
                      &nbsp; This Agreement is binding upon and inures to the benefit of the parties hereto and their
                      respective permitted successors and assigns.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Voluntary Execution.</strong>
                    <span>
                      &nbsp;Contractor certifies and acknowledges that Contractor has carefully read all of the
                      provisions of this Agreement, that Contractor understands and has voluntarily accepted such
                      provisions, and that Contractor will fully and faithfully comply with such provisions.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Construction.</strong>
                    <span>
                      &nbsp;This Agreement is the result of negotiations between and has been reviewed by each of the
                      parties hereto and their respective counsel, if any; accordingly, this Agreement shall be deemed
                      to be the product of all of the parties hereto, and no ambiguity shall be construed in favor of or
                      against any one of the parties hereto.
                    </span>
                  </li>
                </ol>
                <p>
                  <strong>CONFIDENTIAL INFORMATION </strong>
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='45'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <span>&nbsp;</span>
                    <strong style={{ 'text-decoration': ' underline' }}>Confidential Information.</strong>
                  </li>
                </ol>
                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(a)</strong>
                  <span>&nbsp;</span>
                  <strong style={{ 'text-decoration': ' underline' }}>Protection of Information.</strong>
                  <span>&nbsp;</span>
                  <span>
                    Contractor understands that during the Relationship, the Company and the Company&acute;s
                    clients&nbsp;intend&nbsp;to provide Contractor with certain information, including Confidential
                    Information (as defined below), without which Contractor would not be able to perform
                    Contractor&rsquo;s duties to the Company.&nbsp; Contractor agrees, at all times during the term of
                    the Relationship and thereafter, to hold in strictest confidence, and not to use, except for the
                    benefit of the Company to the extent necessary to perform the Services, and not to disclose to any
                    person, firm, corporation or other entity, without written authorization from the Company in each
                    instance, any Confidential Information (as defined below) that Contractor obtains from the Company
                    or otherwise obtains, accesses or creates in connection with, or as a result of, the Services during
                    the term of the Relationship, whether or not during working hours, until such Confidential
                    Information becomes publicly known and made generally available through no wrongful act of
                    Contractor or of others who were under confidentiality obligations as to the item or items
                    involved.&nbsp; Contractor further agrees not to make copies of such Confidential Information except
                    as authorized by the Company.
                  </span>
                </p>

                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(b) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Confidential Information.</strong>
                  <span>&nbsp; Contractor understands that &ldquo;</span>
                  <span>Confidential Information</span>
                  <span>
                    &rdquo; means any and all information and physical material not generally known or available outside
                    the Company, and information and physical material entrusted to the Company in confidence by third
                    parties, that is disclosed by the Company to Contractor, that is obtained or accessed by Contractor
                    from the Company or third parties related to the Company, or is obtained, accessed, or created by
                    Contractor for the Company in performance of the Services, whether or not such information is
                    patentable, copyrightable or otherwise legally protectable.&nbsp; Confidential Information includes,
                    without limitation': '&nbsp; (i) Company Inventions (as defined below); and (ii) technical data,
                    trade secrets, know-how, research, product or service ideas or plans, software codes and designs,
                    algorithms, developments, inventions, patent applications, laboratory notebooks, processes,
                    formulas, techniques, biological materials, mask works, engineering designs and drawings, hardware
                    configuration information, agreements with third parties, lists of, or information relating to,
                    employees and consultants of the Company (including, but not limited to, the names, contact
                    information, jobs, compensation, and expertise of such employees and consultants), lists of, or
                    information relating to, suppliers and customers (including, but not limited to, customers of the
                    Company on whom Contractor called or with whom Contractor became acquainted during the
                    Relationship), price lists, pricing methodologies, cost data, market share data, marketing plans,
                    licenses, contract information, business plans, financial forecasts, historical financial data,
                    budgets or other business information disclosed to Contractor by the Company either directly or
                    indirectly, whether in writing, electronically, orally, or by observation.
                  </span>
                </p>

                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(c) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>
                    Information Not Subject to Confidentiality Obligations.
                  </strong>
                  <span>
                    &nbsp;For avoidance of doubt, Contractor&rsquo;s obligations under Section 4(a) above shall not
                    extend to information that': ' (i) was in the public domain at the time such information was
                    provided by the Company to Contractor or is made public by a person not having a duty of
                    confidentiality to the Company; (ii) Contractor can demonstrate was independently developed by
                    Contractor at the time such information was provided by the Company to Contractor; (iii) the Company
                    has given Contractor express written permission to disclose; or (iv) is disclosed to a third party
                    solely to the extent such disclosure is compelled by law, provided, however, that except where
                    prohibited by law the Company shall be given reasonable advance notice of Contractor&rsquo;s
                    obligation to disclose information under this clause and the opportunity to oppose such disclosure.
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(d) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Permitted Disclosures of Trade Secrets.</strong>
                  <span>
                    &nbsp;Notwithstanding anything in this Agreement to the contrary, Contractor shall not be prohibited
                    from disclosing in confidence any trade secret (i) to a federal, state, or local government official
                    or an attorney, in either case solely for the purpose of reporting or investigating a suspected
                    violation of law, (ii) in a complaint or other document filed in a lawsuit or other proceeding if
                    such filing is made under seal and protected from public disclosure, or (iii) otherwise as expressly
                    permitted under 18 U.S.C. &sect; 1833(b).
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(e) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Third Party Information.</strong>
                  <span>
                    &nbsp;Contractor&rsquo;s agreements in this Section&nbsp;4 are intended to be for the benefit of the
                    Company and any third party that has entrusted information or physical material to the Company in
                    confidence.&nbsp; Contractor further agrees that, during the term of the Relationship and
                    thereafter, Contractor will not improperly use or disclose to the Company any confidential,
                    proprietary or secret information of Contractor&rsquo;s former clients or any other person, and
                    Contractor agrees not to bring any such information onto the Company&rsquo;s property or place of
                    business.
                  </span>
                </p>

                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(f)</strong>
                  <strong style={{ 'text-decoration': ' underline' }}>&nbsp;Other Rights.</strong>
                  <span>
                    &nbsp;This Agreement is intended to supplement, and not to supersede, any rights the Company may
                    have in law or equity with respect to the protection of trade secrets or confidential or proprietary
                    information.
                  </span>
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='46'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Ownership of Inventions.</strong>
                  </li>
                </ol>
                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(a) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Maintenance of Records.</strong>
                  <span>
                    &nbsp;Contractor shall keep and maintain adequate and current written records of all Company
                    Inventions made or conceived by Contractor or Contractor&rsquo;s personnel (solely or jointly with
                    others) during the term of the Relationship.&nbsp;The records may be in the form of notes, sketches,
                    drawings, flow charts, electronic data or recordings, laboratory notebooks, or any other
                    format.&nbsp; The records will be available to and remain the sole property of the Company at all
                    times.&nbsp; Contractor shall not remove such records from the Company&rsquo;s place of business
                    except as expressly permitted by Company policy which may, from time to time, be revised at the sole
                    election of the Company for the purpose of furthering the Company&rsquo;s business.&nbsp; Contractor
                    agrees to deliver all such records (including any copies thereof) to the Company at the time of
                    termination of the Relationship as provided for in Section&nbsp;6.
                  </span>
                </p>
                <p>
                  <span />
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(b) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Intellectual Property Rights.&nbsp;</strong>
                  <span>
                    Contractor shall assist the Company, or its designee, at Company&rsquo;s expense, in every proper
                    way to secure the Company&rsquo;s, or its designee&rsquo;s, rights in the Company Inventions and any
                    copyrights, patents, trademarks, mask work rights, Moral Rights, or other intellectual property
                    rights relating thereto in any and all countries, including the disclosure to the Company or its
                    designee of all pertinent information and data with respect thereto, the execution of all
                    applications, specifications, oaths, assignments, recordations, and all other instruments which the
                    Company or its designee shall deem necessary in order to apply for, obtain, maintain and transfer
                    such rights, or if not transferable, waive and agree never to assert such rights, and in order to
                    assign and convey to the Company or its designee, and any successors, assigns and nominees the sole
                    and exclusive right, title and interest in and to such Company Inventions, and any copyrights,
                    patents, mask work rights or other intellectual property rights relating thereto.&nbsp; Contractor
                    further agrees that Contractor&rsquo;s obligation to execute or cause to be executed, when it is in
                    Contractor&rsquo;s power to do so, any such instrument or papers shall continue during and at all
                    times after the end of the Relationship and until the expiration of the last such intellectual
                    property right to expire in any country of the world.&nbsp; Contractor hereby irrevocably designates
                    and appoints the Company and its duly authorized officers and agents as Contractor&rsquo;s agent and
                    attorney-in-fact, to act for and in Contractor&rsquo;s behalf and stead to execute and file any such
                    instruments and papers and to do all other lawfully permitted acts to further the application for,
                    prosecution, issuance, maintenance or transfer of letters patent, copyright, mask work and other
                    registrations related to such Company Inventions.&nbsp; This power of attorney is coupled with an
                    interest and shall not be affected by Contractor&rsquo;s subsequent incapacity.
                  </span>
                  <span>&nbsp;&zwnj;</span>
                </p>
                <p>
                  <span />
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(c) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Exception to Assignments.</strong>
                  <span>
                    &nbsp; Subject to the requirements of applicable state law, if any, Contractor understands that the
                    Company Inventions will not include, and the provisions of this Agreement requiring assignment of
                    inventions to the Company do not apply to, any invention which qualifies fully for exclusion under
                    the provisions of applicable state law, if any.&nbsp; In order to assist in the determination of
                    which inventions qualify for such exclusion, Contractor will advise the Company promptly in writing,
                    during and for a period of twelve (12) months immediately following the termination of the
                    Relationship, of all Inventions solely or jointly conceived or developed or reduced to practice by
                    Contractor or Contractor&rsquo;s personnel in connection with, or as a result of, the Services
                    performed for the Company during the period of the Relationship.
                  </span>
                  <span>&nbsp;</span>
                </p>
                <p>
                  <span />
                </p>
                <ol className='c15 lst-kix_a9rshy4n8jt4-0' start='47'>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>
                      Company Property; Returning Company Documents.
                    </strong>
                    <span>
                      &nbsp;Contractor acknowledges and agrees that Contractor has no expectation of privacy with
                      respect to the Company&rsquo;s telecommunications, networking or information processing systems
                      (including, without limitation, files, e-mail messages, and voice messages) and that
                      Contractor&rsquo;s activity and any files or messages on or using any of those systems may be
                      monitored or reviewed at any time without notice.&nbsp; Contractor further agrees that any
                      property situated on the Company&rsquo;s premises or systems and owned by the Company, including
                      disks and other storage media, filing cabinets or other work areas, is subject to inspection by
                      Company personnel at any time with or without notice.&nbsp; Contractor agrees that, at the time of
                      termination of the Relationship, Contractor will deliver to the Company (and will not keep in
                      Contractor&rsquo;s possession, recreate or deliver to anyone else) any and all devices, records,
                      data, notes, reports, proposals, lists, correspondence, specifications, drawings, blueprints,
                      sketches, laboratory notebooks, materials, flow charts, equipment, other documents or property, or
                      reproductions of any of the aforementioned items developed by Contractor or Contractor&rsquo;s
                      personnel pursuant to the Relationship or otherwise belonging to the Company, its successors or
                      assigns.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Notice to Third Parties.</strong>
                    <span>
                      &nbsp;Contractor agrees that during the periods of time during which Contractor is restricted in
                      taking certain actions by the terms of this Agreement (the &ldquo;
                    </span>
                    <span style={{ 'text-decoration': ' underline' }}>Restriction Period</span>
                    <span>
                      &rdquo;), Contractor shall inform any entity or person with whom Contractor may seek to enter into
                      a business relationship (whether as an owner, employee, independent contractor or otherwise) of
                      Contractor&rsquo;s contractual obligations under this Agreement.&nbsp; Contractor also understands
                      and agrees that the Company may, with or without prior notice to Contractor and during or after
                      the term of the Relationship, notify third parties of Contractor&rsquo;s agreements and
                      obligations under this Agreement.&nbsp; Contractor further agrees that, upon written request by
                      the Company, Contractor will respond to the Company in writing regarding the status of
                      Contractor&rsquo;s engagement or proposed engagement with any party during the Restriction Period.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Other Parties.</strong>
                    <span>
                      &nbsp;Contractor agrees that during the term of the Relationship, Contractor will not negatively
                      influence any of the Company&rsquo;s clients, licensors, licensees or customers from purchasing
                      Company products or services or solicit or influence or attempt to influence any client, licensor,
                      licensee, customer or other person either directly or indirectly, to direct any purchase of
                      products and/or services to any person, firm, corporation, institution or other entity in
                      competition with the business of the Company.&nbsp; In addition, Contractor acknowledges that the
                      Company has valuable Trade Secrets (as defined by applicable law from time to time) to which
                      Contractor will have access during the term of the Relationship.&nbsp; Contractor understands that
                      the Company intends to vigorously pursue its rights under applicable Trade Secrets law if, during
                      a period of twelve (12) months immediately following the termination of the Relationship for any
                      reason, whether with or without cause, Contractor solicits or influences or attempts to influence
                      any client, licensor, licensee, customer or other person either directly or indirectly, to direct
                      any purchase of products and/or services to any person, firm, corporation, institution or other
                      entity in competition with the business of the Company.&nbsp; Thereafter, the Company intends to
                      vigorously pursue its rights under applicable Trade Secrets law as the circumstances warrant.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>No Change to Duration of Relationship.</strong>
                    <span>
                      &nbsp;Contractor understands and acknowledges that this Agreement does not alter, amend or expand
                      upon any rights Contractor may have to continue in the consulting relationship with, or in the
                      duration of Contractor&rsquo;s consulting relationship with, the Company under any existing
                      agreements between the Company and Contractor, including without limitation the Services
                      Agreement, or under applicable law.
                    </span>
                  </li>
                  <li style={{ 'margin-bottom': '20px' }}>
                    <strong style={{ 'text-decoration': ' underline' }}>Representations and Covenants.</strong>
                  </li>
                </ol>
                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(a) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Facilitation of Agreement.</strong>
                  <span>
                    &nbsp;Contractor agrees to execute promptly, both during and after the end of the Relationship, any
                    proper oath, and to verify any proper document, required to carry out the terms of this Agreement,
                    upon the Company&rsquo;s written request to do so.
                  </span>
                </p>

                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(b) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>No Conflicts.</strong>
                  <span>
                    &nbsp;Contractor represents and warrants that Contractor&rsquo;s performance of all the terms of
                    this Agreement does not and will not breach any agreement Contractor has entered into, or will enter
                    into, with any third party, including without limitation any agreement to keep in confidence
                    proprietary information or materials acquired by Contractor in confidence or in trust prior to or
                    during the Relationship.&nbsp; Contractor will not disclose to the Company or use any inventions,
                    confidential or non-public proprietary information or material belonging to any previous client,
                    employer or any other party.&nbsp; Contractor will not induce the Company to use any inventions,
                    confidential or non-public proprietary information, or material belonging to any previous client,
                    employer or any other party.&nbsp; Contractor represents and warrants that Contractor has{' '}
                  </span>
                  <span>no</span>
                  <span>&nbsp;agreement (</span>
                  <span>e.g.,</span>
                  <span>
                    &nbsp;non-competition agreements, non-solicitation of customers agreements, non-solicitation of
                    employees agreements, confidentiality agreements, inventions agreements, etc.) with a current or
                    former client, employer, or any other person or entity, that may restrict Contractor&rsquo;s ability
                    to perform services for the Company or Contractor&rsquo;s ability to recruit or engage customers or
                    service providers on behalf of the Company, or otherwise relate to or restrict Contractor&rsquo;s
                    ability to perform Contractor&rsquo;s duties for the Company or any obligation Contractor may have
                    to the Company.&nbsp; Contractor agrees not to enter into any written or oral agreement that
                    conflicts with the provisions of this Agreement.
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <span>
                    Contractor further represents that Contractor does not presently perform or intend to perform,
                    during the term of the Services Agreement, consulting or other services for, and Contractor is not
                    presently employed by and has no intention of being employed by, companies whose businesses or
                    proposed businesses in any way involve products or services that would be competitive with the
                    Company&rsquo;s products or services, or those products or services proposed or in development by
                    the Company during the term of the Services Agreement.If, however, Contractor decides to do so,
                    Contractor agrees that, in advance of accepting such employment or agreeing to perform such
                    services, Contractor will promptly notify the Company in writing, specifying the organization to
                    which Contractor proposes to render services, and provide information sufficient to allow the
                    Company to determine if such work would conflict with the interests of the Company.
                  </span>
                </p>
                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(c) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Voluntary Execution.</strong>
                  <span>
                    &nbsp;Contractor certifies and acknowledges that Contractor has carefully read all of the provisions
                    of this Agreement, that Contractor understands and has voluntarily accepted such provisions, and
                    that Contractor will fully and faithfully comply with such provisions.
                  </span>
                </p>

                <p style={{ 'margin-left': ' 40px' }}>
                  <strong>(d) </strong>
                  <strong style={{ 'text-decoration': ' underline' }}>Advice of Counsel.</strong>
                  <span>&nbsp;</span>
                  <span>
                    CONTRACTOR ACKNOWLEDGES THAT, IN EXECUTING THIS AGREEMENT, CONTRACTOR HAS HAD THE OPPORTUNITY TO
                    SEEK THE ADVICE OF INDEPENDENT LEGAL COUNSEL, AND CONTRACTOR HAS READ AND UNDERSTANDS ALL OF THE
                    TERMS AND PROVISIONS OF THIS AGREEMENT.&nbsp; THIS AGREEMENT SHALL NOT BE CONSTRUED AGAINST ANY
                    PARTY BY REASON OF THE DRAFTING OR PREPARATION HEREOF.
                  </span>
                </p>
                <p>
                  <span>&zwnj;</span>
                </p>

                <p style={{ 'text-align': ' center' }}>
                  <strong>EXHIBIT 1</strong>
                </p>

                <p style={{ 'text-align': ' center' }}>
                  <strong>TERMINATION CERTIFICATION MODEL</strong>
                </p>

                <p>
                  <span>
                    This is to certify that Contractor does not have in Contractor&rsquo;s possession, nor has
                    Contractor failed to return, any devices, records, data, notes, reports, proposals, lists,
                    correspondence, specifications, drawings, blueprints, sketches, laboratory notebooks, flow charts,
                    materials, equipment, other documents or property, or copies or reproductions of any aforementioned
                    items belonging to&nbsp;Advanced Engineering Services LLC., a Delaware limited liability company,
                    its subsidiaries, affiliates, successors or assigns (collectively, the &ldquo;
                  </span>
                  <span style={{ 'text-decoration': ' underline' }}>Company</span>
                </p>

                <p>
                  <span>
                    Contractor further certifies that Contractor has complied with all the terms of the Company&rsquo;s
                    Confidential Information and Invention Assignment Agreement signed by Contractor, including the
                    reporting of any Inventions (as defined therein), conceived or made by Contractor or
                    Contractor&rsquo;s personnel (solely or jointly with others) covered by that agreement, and
                    Contractor acknowledges Contractor&rsquo;s continuing obligations under that agreement.
                  </span>
                </p>

                <p>
                  <span>
                    Contractor further agrees that, in compliance with the Confidential Information and Invention
                    Assignment Agreement, Contractor will preserve as confidential all trade secrets, confidential
                    knowledge, data or other proprietary information relating to products, processes, know-how, designs,
                    formulas, developmental or experimental work, computer programs, data bases, other original works of
                    authorship, customer lists, business plans, financial information or other subject matter pertaining
                    to any business of the Company or any of its employees, clients, consultants or licensees.
                  </span>
                </p>

                <p>
                  <span>
                    Contractor further agrees that for twelve (12) months immediately following the termination of
                    Contractor&rsquo;s Relationship with the Company, Contractor shall not either directly or indirectly
                    solicit, induce, recruit or encourage any of the Company&rsquo;s employees or consultants to
                    terminate their relationship with the Company, or attempt to solicit, induce, recruit, encourage or
                    take away employees or consultants of the Company, either for Contractor or for any other person or
                    entity.
                  </span>
                </p>

                <p>
                  <span>
                    Further, Contractor agrees that Contractor shall not use any Confidential Information of the Company
                    to negatively influence any of the Company&rsquo;s clients or customers from purchasing Company
                    products or services or to solicit or influence or attempt to influence any client, customer or
                    other person either directly or indirectly, to direct any purchase of products and/or services to
                    any person, firm, corporation, institution or other entity in competition with the business of the
                    Company.
                  </span>
                </p>

                <p>
                  <span>
                    Further, Contractor acknowledges that the Company has valuable Trade Secrets (as defined by
                    applicable law from time to time) to which Contractor has had access.&nbsp; Contractor understands
                    that the Company intends to vigorously pursue its rights under applicable Trade Secrets law if,
                    during a period of twelve (12) months from the date of this Certification, Contractor solicits or
                    influences or attempts to influence any client, licensor, licensee, customer or other person either
                    directly or indirectly, to direct any purchase of products and/or services to any person, firm,
                    corporation, institution or other entity in competition with the business of the Company.&nbsp;
                    Thereafter, the Company intends to vigorously pursue its rights under applicable Trade Secrets law
                    as the circumstances warrant.
                  </span>
                </p>
              </div>
            </Text>
          </Box>
        </Flex>
      </Layout>
    </>
  );
};

export default GeneralTermsOfEngagement;
